export const GENRE_HOME_PAGE_TITLE: Record<string, string> = {
  '/webtoon/recommendation': '리디 RIDI - 웹툰 만화 웹소설 전자책',
  '/webtoon/romance': '로맨스 웹툰',
  '/webtoon/bl': 'BL 웹툰',
  '/comics/bl': 'BL 만화',
  '/ebook/recommendation': '도서 전자책',
  '/romance/webnovel': '웹소설',
  '/comics/geukhwa': '극화 만화',
  // 자동 장르홈
  '/comics/view-category-1521': '국내 순정 만화',
  '/comics/view-category-1522': '해외 순정 만화',
  '/comics/view-category-1519': '드라마 만화',
  '/comics/view-category-1535': '성인 만화',
  '/comics/view-category-1551': '할리퀸 만화',
  '/comics/view-category-1513': '무협 만화',
  '/comics/view-category-1517': '학원 만화',
  '/comics/view-category-1515': '액션 만화',
  '/comics/view-category-1527': '판타지/SF 만화',
  '/comics/view-category-1525': '스포츠 만화',
  '/comics/view-category-1523': '코믹 만화',
  '/comics/view-category-1537': 'GL 만화',
  '/comics/view-category-1531': '공포/추리 만화',
  '/comics/view-category-1509': '만화잡지',
  '/ebook/view-category-100': '소설',
  '/ebook/view-category-200': '경영/경제 도서',
  '/ebook/view-category-400': '인문/사회/역사 도서',
  '/ebook/view-category-300': '자기계발 도서',
  '/ebook/view-category-110': '에세이/시 도서',
  '/ebook/view-category-800': '여행 도서',
  '/ebook/view-category-700': '종교 도서',
  '/ebook/view-category-1000': '외국어 도서',
  '/ebook/view-category-1100': '과학 도서',
  '/ebook/view-category-1900': '진로/교육/교재 도서',
  '/ebook/view-category-2200': '컴퓨터/IT 도서',
  '/ebook/view-category-500': '건강/다이어트 도서',
  '/ebook/view-category-600': '가정/생활 도서',
  '/ebook/view-category-1300': '어린이/청소년 도서',
  '/ebook/view-category-1400': '해외도서',
  '/ebook/view-category-2000': '잡지',
};

export const GENRE_HOME_HEADER_TEXT: Record<string, string> = {
  '/webtoon/recommendation': '리디 웹툰',
  '/webtoon/romance': '리디 로맨스 웹툰',
  '/webtoon/bl': '리디 BL 웹툰',
  '/comics/ebook': '리디 만화',
  '/comics/bl': '리디 BL 만화',
  '/comics/serial': '리디 일본만화 연재',
  '/romance/webnovel': '리디 웹소설',
  '/romance/ebook': '리디 로맨스 소설',
  '/romance-fantasy/webnovel': '리디 로판 웹소설',
  '/romance-fantasy/ebook': '리디 로판 소설',
  '/fantasy/webnovel': '리디 판타지 웹소설',
  '/fantasy/ebook': '리디 판타지 소설',
  '/bl/webnovel': '리디 BL 웹소설',
  '/bl/ebook': '리디 BL 소설',
  '/ebook/recommendation': '리디 전자책 추천',
  '/ebook/event': '리디 전자책 기획전',
  '/comics/geukhwa': '리디 극화 만화',
  // 자동 장르홈
  '/comics/view-category-1521': '리디 국내 순정 만화',
  '/comics/view-category-1522': '리디 해외 순정 만화',
  '/comics/view-category-1519': '리디 드라마 만화',
  '/comics/view-category-1535': '리디 성인 만화',
  '/comics/view-category-1551': '리디 할리퀸 만화',
  '/comics/view-category-1513': '리디 무협 만화',
  '/comics/view-category-1517': '리디 학원 만화',
  '/comics/view-category-1515': '리디 액션 만화',
  '/comics/view-category-1527': '리디 판타지/SF 만화',
  '/comics/view-category-1525': '리디 스포츠 만화',
  '/comics/view-category-1523': '리디 코믹 만화',
  '/comics/view-category-1537': '리디 GL 만화',
  '/comics/view-category-1531': '리디 공포/추리 만화',
  '/comics/view-category-1509': '리디 만화잡지',
  '/ebook/view-category-100': '리디 소설 전자책',
  '/ebook/view-category-200': '리디 경영/경제 도서 전자책',
  '/ebook/view-category-400': '리디 인문/사회/역사 도서 전자책',
  '/ebook/view-category-300': '리디 자기계발 도서 전자책',
  '/ebook/view-category-110': '리디 에세이/시 도서 전자책',
  '/ebook/view-category-800': '리디 여행 도서 전자책',
  '/ebook/view-category-700': '리디 종교 도서 전자책',
  '/ebook/view-category-1000': '리디 외국어 도서 전자책',
  '/ebook/view-category-1100': '리디 과학 도서 전자책',
  '/ebook/view-category-1900': '리디 진로/교육/교재 도서 전자책',
  '/ebook/view-category-2200': '리디 컴퓨터/IT 도서 전자책',
  '/ebook/view-category-500': '리디 건강/다이어트 도서 전자책',
  '/ebook/view-category-600': '리디 가정/생활 도서 전자책',
  '/ebook/view-category-1300': '리디 어린이/청소년 도서 전자책',
  '/ebook/view-category-1400': '리디 해외도서 전자책',
  '/ebook/view-category-2000': '리디 잡지 전자책',
};

export const GENRE_HOME_META_DESCRIPTION: Record<string, string> = {
  '/webtoon/recommendation':
    '웹툰, 만화, 웹소설, 전자책 모두 리디에서 만나 보세요! 리디 독점, 할인, 무료감상 등 리디만의 특별한 혜택과 함께 다양한 이야기를 마음껏 즐기세요.',
  '/webtoon/romance':
    '짝사랑부터 연애까지 달달하고 설레는 로맨스 웹툰을 리디에서 만나 보세요! 리디 독점, 할인, 무료감상 등 리디만의 혜택도 드려요.',
  '/webtoon/bl':
    '광공부터 후회수까지, 소프트와 하드 등 다채로운 BL 웹툰을 리디에서 만나 보세요! 리디 독점, 할인, 무료감상 등 리디만의 혜택도 드려요.',
  '/romance/webnovel':
    '로맨스부터 판타지까지 다양한 장르의 웹소설을 리디에서 만나 보세요! 리디 독점, 할인, 무료감상 등 리디만의 특별한 혜택도 드려요.',
  '/romance/ebook':
    '설렘 가득한 로맨스 웹소설 단행본을 리디에서 감상해 보세요! 리디 독점, 위클리 쿠폰, 특별 할인 등 리디만의 혜택도 누리세요.',
  '/romance-fantasy/webnovel':
    '환상적인 로맨스 판타지 웹소설을 리디에서 만나 보세요! 리디 독점, 기다리면 무료, 특별 할인 등 리디만의 혜택도 누리세요.',
  '/romance-fantasy/ebook':
    '몽환적인 로맨스 판타지 웹소설 단행본을 리디에서 감상해 보세요! 리디 독점, 위클리 쿠폰, 특별 할인 등 리디만의 혜택도 누리세요.',
  '/fantasy/webnovel':
    '흥미진진한 판타지 웹소설을 리디에서 만나 보세요! 리디 독점, 기다리면 무료, 특별 할인 등 리디만의 혜택도 누리세요.',
  '/fantasy/ebook':
    '신비한 판타지 웹소설 단행본을 리디에서 감상해 보세요! 리디 독점, 위클리 쿠폰, 특별 할인 등 리디만의 혜택도 누리세요.',
  '/bl/webnovel':
    '나만 알고 싶은 BL 웹소설을 리디에서 만나 보세요! 리디 독점, 기다리면 무료, 특별 할인 등 리디만의 혜택도 누리세요.',
  '/bl/ebook':
    '소장각 BL 웹소설 단행본을 리디에서 감상해 보세요! 리디 독점, 위클리 쿠폰, 특별 할인 등 리디만의 혜택도 누리세요.',
  '/comics/ebook':
    '일본 만화와 그 외 다수의 인기 만화책을 리디에서 e북으로 감상해 보세요! 최저가 세트, 할인, 무료감상 등 리디만의 혜택도 드려요.',
  '/comics/serial':
    '순정 만화부터 성인 만화까지 다양한 만화를 리디에서 감상해 보세요! 한일 동시연재, 할인, 무료감상 등 리디만의 혜택도 드려요.',
  '/comics/bl':
    '훈훈함부터 19금까지 BL 만화를 리디에서 만나 보세요! 리디 독점, 할인, 포인트 이벤트 등 리디만의 혜택도 드려요.',
  '/ebook/recommendation':
    '읽고 싶은 도서를 언제 어디서나 전자책으로 감상해 보세요! 이달의 혜택, 특가 세트 등 리디만의 혜택을 다양하게 준비했어요.',
  '/comics/geukhwa':
    '드라마, 영화를 보는 듯한 섬세한 극화 만화를 리디에서 e북으로 감상해 보세요! 대여, 할인, 무료감상, 위클리 쿠폰 등 리디만의 혜택도 드려요.',
  // 자동 장르홈
  '/comics/view-category-1521':
    '옛날 한국 순정 만화가 그리울 때 리디에서 e북으로 감상해 보세요! 대여, 할인, 무료감상, 위클리 쿠폰 등 리디만의 혜택도 드려요.',
  '/comics/view-category-1522':
    '사랑은 만국 공통! 해외 순정 만화를 리디에서 e북으로 감상해 보세요! 대여, 할인, 무료감상, 위클리 쿠폰 등 리디만의 혜택도 드려요.',
  '/comics/view-category-1519':
    '일상 만화를 포함한 드라마 만화를 리디에서 e북으로 감상해 보세요! 대여, 할인, 무료감상, 위클리 쿠폰 등 리디만의 혜택도 드려요.',
  '/comics/view-category-1535':
    '진짜 어른들을 위해 준비된 성인 만화를 리디에서 e북으로 감상해 보세요! 대여, 할인, 무료감상, 위클리 쿠폰 등 리디만의 혜택도 드려요.',
  '/comics/view-category-1551':
    '클래식 신데렐라 스토리를 좋아한다면 할리퀸 만화를 리디에서 e북으로 감상해 보세요! 대여, 할인, 무료감상, 위클리 쿠폰 등 리디만의 혜택도 드려요.',
  '/comics/view-category-1513':
    '진정한 고수의 정통 무협을 느낄 수 있는 무협 만화를 리디에서 e북으로 감상해 보세요! 대여, 할인, 무료감상, 위클리 쿠폰 등 리디만의 혜택도 드려요.',
  '/comics/view-category-1517':
    '풋풋한 감성과 열정이 가득한 학원 만화를 리디에서 e북으로 감상해 보세요! 대여, 할인, 무료감상, 위클리 쿠폰 등 리디만의 혜택도 드려요.',
  '/comics/view-category-1515':
    '강한 자들의 화려한 액션 만화를 리디에서 e북으로 감상해 보세요! 대여, 할인, 무료감상, 위클리 쿠폰 등 리디만의 혜택도 드려요.',
  '/comics/view-category-1527':
    '상상력을 먹고 자란 이세계 만화, 판타지 만화, SF 만화를 리디에서 e북으로 감상해 보세요! 대여, 할인, 무료감상, 위클리 쿠폰 등 리디만의 혜택도 드려요.',
  '/comics/view-category-1525':
    '2D로도 스포츠를 즐길 수 있다! 스포츠 만화를 리디에서 e북으로 감상해 보세요! 대여, 할인, 무료감상, 위클리 쿠폰 등 리디만의 혜택도 드려요.',
  '/comics/view-category-1523':
    '화려하고, 멋지고, 소소하고, 귀여운 다양한 코믹 만화를 리디에서 e북으로 감상해 보세요! 대여, 할인, 무료감상, 위클리 쿠폰 등 리디만의 혜택도 드려요.',
  '/comics/view-category-1537':
    '평범한 일상 이야기부터 뜨거운 19금까지.. 백합 만화를 리디에서 e북으로 감상해 보세요! 대여, 할인, 무료감상, 위클리 쿠폰 등 리디만의 혜택도 드려요.',
  '/comics/view-category-1531':
    '잔잔한 일상에 자극이 필요할 때, 공포 만화, 추리 만화를 리디에서 e북으로 감상해 보세요! 대여, 할인, 무료감상, 위클리 쿠폰 등 리디만의 혜택도 드려요.',
  '/comics/view-category-1509':
    '정기적으로 발행되는 만화잡지를 리디에서 e북으로 감상해 보세요! 대여, 할인, 무료감상, 위클리 쿠폰 등 리디만의 혜택도 드려요.',
  '/ebook/view-category-100':
    '다양한 세상 이야기가 가득한 소설을 리디에서 전자책으로 감상해 보세요! 이달의 혜택, 특가 세트 등 리디만의 혜택을 다양하게 준비했어요.',
  '/ebook/view-category-200':
    '경영, 경제, 마케팅, 재테크, 부동산 관련 전문 도서를 리디에서 전자책으로 감상해 보세요! 이달의 혜택, 특가 세트 등 리디만의 혜택을 다양하게 준비했어요.',
  '/ebook/view-category-400':
    '정치, 사회, 문화, 예술, 인문, 역사 서적을 리디에서 전자책으로 감상해 보세요! 이달의 혜택, 특가 세트 등 리디만의 혜택을 다양하게 준비했어요.',
  '/ebook/view-category-300':
    '성공, 리더십, 화술, 인간관계에 필요한 자기계발 서적을 리디에서 전자책으로 감상해 보세요! 이달의 혜택, 특가 세트 등 리디만의 혜택을 다양하게 준비했어요.',
  '/ebook/view-category-110':
    '진정한 마음의 양식! 에세이, 시를 리디에서 전자책으로 감상해 보세요! 이달의 혜택, 특가 세트 등 리디만의 혜택을 다양하게 준비했어요.',
  '/ebook/view-category-800':
    '국내외 여행의 길라잡이, 여행 서적이 필요하다면 리디에서 전자책으로 감상해 보세요! 이달의 혜택, 특가 세트 등 리디만의 혜택을 다양하게 준비했어요.',
  '/ebook/view-category-700':
    '각종 종교에 대한 심층 이해를 돕는 종교 서적을 리디에서 전자책으로 감상해 보세요! 이달의 혜택, 특가 세트 등 리디만의 혜택을 다양하게 준비했어요.',
  '/ebook/view-category-1000':
    '영어, 일본어, 중국어 등 각종 외국어 서적을 리디에서 전자책으로 확인해 보세요! 이달의 혜택, 특가 세트 등 리디만의 혜택을 다양하게 준비했어요.',
  '/ebook/view-category-1100':
    '과학, 수학을 이야기로 배울 수 있는 도서를 리디에서 전자책으로 감상해 보세요! 이달의 혜택, 특가 세트 등 리디만의 혜택을 다양하게 준비했어요.',
  '/ebook/view-category-1900':
    '공부법, 수능 논술, 진로 탐색, 수험서 등 진로를 위한 도서를 리디에서 전자책으로 확인해 보세요! 이달의 혜택, 특가 세트 등 리디만의 혜택을 다양하게 준비했어요.',
  '/ebook/view-category-2200':
    '개발, 프로그래밍, IT자격증 공부가 필요할 때 리디에서 전자책으로 확인해 보세요! 이달의 혜택, 특가 세트 등 리디만의 혜택을 다양하게 준비했어요.',
  '/ebook/view-category-500':
    '다이어트, 운동, 뷰티 등 신체 건강을 위한 도서를 리디에서 전자책으로 감상해 보세요! 이달의 혜택, 특가 세트 등 리디만의 혜택을 다양하게 준비했어요.',
  '/ebook/view-category-600':
    '결혼, 임신, 출산, 육아, 취미생활을 탐구할 수 있는 도서를 리디에서 전자책으로 감상해 보세요! 이달의 혜택, 특가 세트 등 리디만의 혜택을 다양하게 준비했어요.',
  '/ebook/view-category-1300':
    '유아, 어린이, 청소년을 위한 책도 리디에서 전자책으로 감상해 보세요! 이달의 혜택, 특가 세트 등 리디만의 혜택을 다양하게 준비했어요.',
  '/ebook/view-category-1400':
    '유명한 해외도서의 외국어 원서를 리디에서 전자책으로 감상해 보세요! 이달의 혜택, 특가 세트 등 리디만의 혜택을 다양하게 준비했어요.',
  '/ebook/view-category-2000':
    '경제잡지, 과학잡지, 패션잡지, 여성잡지도 리디에서 전자책으로 감상해 보세요! 이달의 혜택, 특가 세트 등 리디만의 혜택을 다양하게 준비했어요.',
};
