import { useTheme } from '@emotion/react';
import { RIDI_NAVIGATION_KEY, setCookie } from '@ridi-web/common';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { BookLazyLoadObserverProvider } from '@/components/common/Book';
import { Footer } from '@/components/common/Footer';
import { GlobalNavigationBar } from '@/components/common/GlobalNavigationBar';
import { PageContextProvider, type PageContextType } from '@/components/common/PageContext';
import { PageHead } from '@/components/common/PageHead';
import { TopButton } from '@/components/common/TopButton';
import { GenreHomeContextProvider } from '@/components/genreHome/common/GenreHomeContext';
import { GenreHomeSection } from '@/components/genreHome/common/GenreHomeSection';
import { GenreHomeTab } from '@/components/genreHome/common/GenreHomeTab';
import { fetchViewsAction, sectionFramesSelector, viewSelector } from '@/features/genreHome/views/viewsSlice';
import {
  addVisitedNavigationIdsAction,
  currentNavigationRouteSelector,
  currentPathSelector,
  initNavigationAction,
  isVisitedNavigationIdsUpdatedSelector,
  rootNavigationSelector,
  visitedNavigationIdsSelector,
} from '@/features/global/globalNavigationBar/navigation/navigationSlice';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { wrapper } from '@/pages/wrapper';
import { encodeVisitedNavigationIds, findCurrentNavigationRoute, findLeafNavigation } from '@/utils/navigation';
import {
  buildGenreHomePageTitle,
  GENRE_HOME_HEADER_TEXT,
  GENRE_HOME_META_DESCRIPTION,
} from '@/utils/pageMetaData/genreHome';
import sendException from '@/utils/sentry/sendException';

import * as styles from './index.styles';

const GenreHomeAddPWASection = dynamic<PropsWithChildren>(
  () => import('@/components/genreHome/common/GenreHomeAddPWASection').then(res => res.GenreHomeAddPWASection),
  {
    ssr: false,
  },
);

type SubHomeProps = {
  seed: string;
  title: string;
  mainHome: string;
  subHome: string;
};

type SubHomeParams = {
  mainHome: string;
  subHome: string;
};

const SubHome = ({ title, seed, mainHome, subHome }: SubHomeProps): ReactJSX.Element => {
  const view = useSelector(viewSelector);
  const sectionFrames = useSelector(sectionFramesSelector);
  const currentPath = useSelector(currentPathSelector);
  const [, selectedGlobalNavigation, selectedTabItem, selectedSubTabItem] =
    useSelector(currentNavigationRouteSelector) ?? [];
  const pageContext: PageContextType = useMemo(
    () => ({
      screenName: 'genrehome',
      params: {
        view_path: currentPath,
      },
      currentNavigationParams: {
        current_genre: selectedGlobalNavigation?.title,
        current_tab: selectedTabItem?.title,
        current_sub_tab: selectedSubTabItem?.title,
      },
    }),
    [currentPath, selectedGlobalNavigation?.title, selectedSubTabItem?.title, selectedTabItem?.title],
  );
  const dispatch = useAppDispatch();
  const visitedNavigationIds = useSelector(visitedNavigationIdsSelector);
  const isVisitedNavigationIdsUpdated = useSelector(isVisitedNavigationIdsUpdatedSelector);
  const h1Text = useMemo(() => (currentPath ? GENRE_HOME_HEADER_TEXT[currentPath] || '리디' : '리디'), [currentPath]);

  useEffect(() => {
    if (!visitedNavigationIds) {
      return;
    }

    if (isVisitedNavigationIdsUpdated) {
      // Flush to cookie
      setCookie(RIDI_NAVIGATION_KEY, encodeVisitedNavigationIds(visitedNavigationIds), {
        path: '/',
        maxAge: 60 * 60 * 24 * 365,
      });
      return;
    }

    // Add to navigation
    dispatch(addVisitedNavigationIdsAction());
  }, [dispatch, visitedNavigationIds, isVisitedNavigationIdsUpdated]);

  const genreHomeContext = useMemo(() => ({ seed }), [seed]);
  const theme = useTheme();
  const pageTitle = useMemo(
    () =>
      buildGenreHomePageTitle({
        currentPath,
        navigationTitle: title,
        viewTitle: view?.title,
      }),
    [currentPath, title, view?.title],
  );

  return (
    <PageContextProvider value={pageContext}>
      <GenreHomeContextProvider value={genreHomeContext}>
        <PageHead
          title={pageTitle}
          disableTitleSuffix
          canonicalPath={`/${mainHome}/${subHome}`}
          meta={{
            description: GENRE_HOME_META_DESCRIPTION[`/${mainHome}/${subHome}`],
            ogDescription: GENRE_HOME_META_DESCRIPTION[`/${mainHome}/${subHome}`],
          }}
        />
        <Head>
          <meta name="theme-color" content={theme.colors.white} />
        </Head>
        <h1 css={styles.hiddenH1Style}>{h1Text}</h1>
        <GlobalNavigationBar showSearch />
        <GenreHomeTab />
        <main css={styles.mainStyle}>
          <BookLazyLoadObserverProvider>
            {sectionFrames.map((sectionFrame, index) => (
              <GenreHomeSection key={sectionFrame.id} sectionFrame={sectionFrame} sectionIndex={index} />
            ))}
          </BookLazyLoadObserverProvider>
        </main>
        <GenreHomeAddPWASection />
        <Footer css={styles.footerStyle} />
        <TopButton />
      </GenreHomeContextProvider>
    </PageContextProvider>
  );
};

export const getServerSideProps = wrapper.getServerSideProps<SubHomeProps, SubHomeParams>(store => async ctx => {
  const { mainHome, subHome } = ctx.params ?? {};
  if (!mainHome || !subHome) {
    return {
      notFound: true,
    };
  }

  const initNavigationActionResult = await store.dispatch(
    initNavigationAction({ req: ctx.req, currentPath: `/${mainHome}/${subHome}` }),
  );

  if (initNavigationAction.rejected.match(initNavigationActionResult)) {
    const NavigationApiError = new Error('navigation api 호출 중 문제가 발생하였습니다.');
    const { payload, error } = initNavigationActionResult;

    sendException(NavigationApiError, {
      level: 'fatal',
      extra: {
        payload: JSON.stringify(payload?.response ? payload.response?.data : payload ?? error),
      },
    });
    throw NavigationApiError;
  }

  const rootNavigation = rootNavigationSelector(store.getState());
  if (!rootNavigation) {
    return {
      notFound: true,
    };
  }

  const currentNavigationRoute = currentNavigationRouteSelector(store.getState());
  if (!currentNavigationRoute) {
    // 해당하는 내비게이션이 없는 경우

    const mainHomeNavigation = findCurrentNavigationRoute(`/${mainHome}`, rootNavigation)?.at(-1);
    if (mainHomeNavigation) {
      /* /webtoon/non-existing-navigation 으로 접근한 경우
       * (기존에 있었지만, 내비게이션에서 사라지는 바람에 새로고침했더니 404가 나오는 경우)
       * /webtoon/ 하위의 마지막으로 방문한 내비게이션으로 리다이렉션 시켜준다. */
      const visitedNavigationIds = visitedNavigationIdsSelector(store.getState());
      const nextLeafCandidate = findLeafNavigation(mainHomeNavigation, visitedNavigationIds);
      return {
        redirect: {
          permanent: false,
          destination: nextLeafCandidate.path,
        },
      };
    }

    // 그냥 이상한 /asdf/zxcv 인 경우
    return { notFound: true };
  }

  const [mainHomeNavigation, currentNavigation] = currentNavigationRoute.slice(-2);
  const endpoint = currentNavigation?.resource_url;
  if (!endpoint) {
    return {
      notFound: true,
    };
  }

  const title = `${mainHomeNavigation?.title ?? ''} ${currentNavigation?.title ?? ''}`;

  const fetchViewsActionResult = await store.dispatch(
    fetchViewsAction({
      reqParams: { body: { endpoint } },
      req: ctx.req,
    }),
  );

  if (fetchViewsAction.rejected.match(fetchViewsActionResult)) {
    const ViewApiError = new Error('view api 호출 중 문제가 발생하였습니다.');
    const { payload, error } = fetchViewsActionResult;

    sendException(ViewApiError, {
      level: 'fatal',
      extra: {
        payload: JSON.stringify(payload?.response ? payload.response?.data : payload ?? error),
      },
    });
  }

  const seed = Math.random().toString(36).slice(2);
  return {
    props: { mainHome, subHome, seed, title },
  };
});

export default SubHome;
